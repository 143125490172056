.About img {
  width: 750px;
  max-width: 95%;
  border-radius: 15px;
  border: 7px double;
  box-shadow: 0 0 5px;
}

.About ul {
  text-align: left;
}

iframe {
  max-width: 100%;
}

.Dates a {
  text-decoration: underline;
  margin-left: 3px;
}

@media (max-width: 500px) {
  nav ul li {
    width: 100%;
    margin: 0.6em auto;
  }
}
